import React from 'react'
import phones from '../../../../assets/Images/phones.png'; 
import laptop from '../../../../assets/Images/laptop.png'; 
import about_b2b from '../../../../assets/Images/about_b2b.png'; 
import about_crm from '../../../../assets/Images/about_crm.png'; 
import about_property from '../../../../assets/Images/about_property.png'; 
import styled from '@emotion/styled'; 
import Paragraph from '../../Paragraph';
import { Box, Container, Grid } from '@mui/material';
import Regtxt from '../../Regtxt'; 
import AboutCard from './Component/Card/Index';
import BlueButton from '../../BlueButton';


const Blackbg = styled(Container)`
    background: transparent;
    overflow: hidden;
    min-height:  ${(props) => props.noht ? "auto" : "100vh"} ;
  `

const ColorGrid = styled(Grid)`
  z-index: 0;
  position: relative;
  background: ${(props) => props.bg ? props.bg : "linear-gradient(25deg ,#FF4141,#FF4141)"};
  gap: 20px 0;
    transition: all .5s;
    &>.MuiGrid-root{
          &>.MuiBox-root {
            &>.image{
              scale: 1;
              transition: all .5s;
            }
          }
         }
`
const About = () => { 


  return (
        <Blackbg maxWidth="xl" id="our-products" > 
          <Grid container gap="20px">  
            <ColorGrid item container xs={12}   sx={{backgroundColor:"#060707"}} borderRadius="8px" padding="0px 20px"> 
              <Grid item xs={12} md={5.9} lg={5.9}  display="flex" flexDirection="column" justifyContent="center" gap="30px" data-aos="zoom-in">    
                {/* <Paragraph variant="h6" fontFamily="MontserratR" textTransform="uppercase" color="#0066FF">brick block</Paragraph> */}
                <Regtxt  fontSize="clamp(2.375rem, 1.375vw + 2.1rem, 3.75rem)">  
                  Rental Stay App
                </Regtxt>
                <Paragraph variant="body1" fontFamily="MontserratR"> 
                The Block Inn Rental Stay app offers a seamless experience for booking short-term rentals. Users can browse and secure stays in top locations with ease, thanks to real-time property data integration. The app is designed for simplicity and convenience, making it easy for guests to find the perfect rental while ensuring a smooth booking process. Whether for a weekend getaway or an extended stay, the Block Inn Rental Stay app delivers a hassle-free rental experience.                </Paragraph>
                <BlueButton bg="#fff" c="#000">Watch Preview</BlueButton>
              </Grid> 
              <Grid item xs={12} md={5.9} lg={5.9} > 
                <Box display='grid' justifyItems='center'> 
                  <img Width="90%" src={phones} alt='brickblock' style={{ maxWidth:'100%' }}/>
                </Box>
              </Grid>  
          </ColorGrid>  
          <ColorGrid item container xs={12} marginTop="70px" bg="0C1420" sx={{backgroundColor:"#0C1420"}} borderRadius="8px" padding="30px 20px"> 
              <Grid item xs={12} md={5.9} lg={5.9} > 
                <Box display='grid' justifyItems='center'> 
                  <img Width="90%" src={laptop} alt='brickblock' style={{ maxWidth:'100%' }}/>
                </Box>
              </Grid>  
              <Grid item xs={12} md={5.9} lg={5.9}  display="flex" flexDirection="column" justifyContent="center" gap="20px" data-aos="zoom-in">    
                {/* <Paragraph variant="h6" fontFamily="MontserratR" textTransform="uppercase" color="#0066FF">brick block</Paragraph> */}
                <Regtxt  fontSize="clamp(2.375rem, 1.375vw + 2.1rem, 3.75rem)">  
                Off-Plan Investment 
                Options
                </Regtxt>
                <Paragraph variant="body1" fontFamily="MontserratR"> 
                Off-plan investments with Block Inn allow investors to purchase properties before they are fully constructed, often at lower prices than market value. This early investment can lead to significant capital appreciation once the property is completed, offering a higher return on investment. Additionally, off-plan buyers often have the flexibility to customize certain aspects of the property. Benefits include access to prime real estate at a discount, potential for higher rental yields, and the ability to spread payments through installment plans, making it a strategic choice for investors looking for growth opportunities.                </Paragraph>
                <BlueButton bg="#fff" c="#000">View Demo</BlueButton>
              </Grid> 
          </ColorGrid>  
          <Grid item xs={12} md={5.8} lg={3.8}>  
            <AboutCard href="" bg={true} imgw='200px' psrc={about_b2b}  def1="B2B-Specific APIs" def2="Enable seamless integration between BlockInn's platform and other businesses, providing real-time access to property data and booking systems tailored to business needs. " /> 
          </Grid> 
          <Grid item xs={12}  md={5.8} lg={3.8}>  
            <AboutCard href="" imgw='300px' psrc={about_property}  def1="Property Management" def2="Streamline property operations with tools designed for efficient management, from tenant communications to maintenance scheduling, all in one platform." />
          </Grid>
          <Grid item xs={12}  md={5.8} lg={3.8}>  
            <AboutCard href="" imgw='200px' psrc={about_crm}  def1="Realtor CRM Integration" def2="Integrate BlockInn's real estate solutions directly into existing CRM systems, allowing realtors to manage leads, track properties, and close deals more effectively. " />
          </Grid>
          </Grid>    

      </Blackbg>
  )
}

export default About;