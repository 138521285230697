import { Button, } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';


const StyledButton = styled(Button)`
text-transform: none;
color: ${ props=> props.c?  props.c: "#fff"};
font-family: "MontserratSB";
width: fit-content;
min-width: 150px;
min-height: 35px;
border-radius: 10px;
font-size:16px;
padding:6px 15px;
background: ${ props=> props.bg?  props.bg: "#FF4141"}; 
border: ${ props=> props.b &&  props.b}; 

&.Mui-disabled {
    color: ${ props=> props.c?  props.c: "#707070 !important"}; 
    border-color: ${ props=> props.bc?  props.bc: "#707070 !important"}; 
    background: ${ props=> props.bg?  props.bg: "#979797 !important"}; 
    cursor: not-allowed !important;
    /* z-index: 2; */
  }
&:hover{
  background-color: ${ props=> props.bg?  props.bg: "#FF4141"};
  color: ${ props=> props.c?  props.c: "#FF4141"};
transition: transform .2s ease-in;
  transform: translate3d(-25%, 0, 0);
  }
@media(max-width: 599px){
        /* font-size: 16px; */

    }
`


const   BlueButtonfirst = (props) => {
  return ( 
    <StyledButton  {...props} > {props.children} </StyledButton>
  );
}
 
export default BlueButtonfirst;