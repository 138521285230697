import React from "react";
import { Grid, Link, Container } from "@mui/material";
import Paragraph from "../../Paragraph";
import logo from "../../../../assets/Images/Bricklogo.png";
import styled from "@emotion/styled";
import { Blackbg } from "../../../Styles/Maintext.styles";
import Maintxt from "../../Maintxt";

const MainIconImg2 = styled.img`
  width: ${props=>props.w? props.w :"160px"};
  height: auto;
`;


const Summary = () => {
  const navLinks = [
  {
      title: "Home",
      link: "#home",
    },
    {
      title: "About",
      link: "#about",
    },
    {
      title: "Our Products",
      link: "#our-products",
    },
    {
      title: "Roadmap",
      link: "#roadmap",
    },
    {
      title: "Tokenomics",
      link: "#tokenomics",
    },
    {
      title: "FAQ",
      link: "#faq",
    },
  ]; 
  return (
    <><Container  maxWidth="xl" sx={{background:"#0C1420",border:"1px solid #1A2940"}} padding="20px 0">

      <Blackbg noht noclr>  
        <Container
          maxWidth="xl"
          sx={{ paddingTop: "50px", paddingBottom: "50px"}}
        >
          <Grid container display="flex" flexDirection={{xs : "column", lg:"row"}} justifyContent="space-between" gap="20px 0" alignItems={{xs:'start'}}>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap:"10px",
                alignItems: {
                  xs: "start",
                  md: "start",
                  marginTop: { xs: "20px", md: "0px" },
                },
              }}
            >
              <Link
                href="/"
                underline="none"
                color="inherit"
                display="flex"
                alignItems={{xs:'start',md:'center'}}
                gap={1}
              >
                <MainIconImg2 src={logo} alt="Brick Block" />
              </Link>
              <Paragraph
                color="#fff"
                variant="h5"
                maxWidth="250px"
                sx={{
                  textAlign: { xs: "start", md: "start" },
                }}
              >
                Rent, Relax, Repeat— 
              </Paragraph>
              <Paragraph
                color="#fff"
                maxWidth="250px"
                sx={{
                  textAlign: { xs: "start", md: "start" },
                }}
              >
                Block Inn revolutionizes real estate with blockchain, offering property management, short-term rentals, and off-plan investments via B2B APIs and real-time data.
              </Paragraph>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap:"5px",
                alignItems: {
                  xs: "start",
                  md: "start",
                  marginTop: { xs: "20px", md: "0px" },
                },
              }}
            >
              <Maintxt variant="h6" color="#fff">Quick Links</Maintxt>
              {navLinks.map((item) => (
                <Link
                  href={item.link}
                  underline="none"
                  color="inherit"
                  key={item.title}
                >
                  <Paragraph  marginTop="0px" marginBottom="0px">
                    {item.title}
                  </Paragraph>
                </Link>
              ))}
            </Grid>  
            
            <Grid
              container
              item
              xs={12}
              sm={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "start", md: "start" },
                marginTop: { xs: "20px", md: "0px" },
              }}
            >
              <Grid item>
                <Maintxt variant="h6" paddingBottom="5px" color="#fff">Resources</Maintxt>
              </Grid>
              <Grid item display="flex" flexDirection= "column" gap="5px">
              <Link
                  href=""
                  underline="none"
                  color="inherit"
                >
                  <Paragraph  marginTop="0px" marginBottom="0px">
                  Whitepaper  
                  </Paragraph>
                </Link>
                <Link
                  href=""
                  underline="none"
                  color="inherit"
                >
                  <Paragraph  marginTop="0px" marginBottom="0px">
                  Gitbook
                  </Paragraph>
                </Link>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "start", md: "start" },
                marginTop: { xs: "20px", md: "0px" },
              }}
            >
              <Grid item>
                <Maintxt variant="h6" paddingBottom="5px" color="#fff">Community</Maintxt>
              </Grid>
              <Grid item display="flex" flexDirection= "column" gap="5px">
              <Link
                  href="https://t.me/blockinnETH"
                  underline="none"
                  color="inherit"
                >
                  <Paragraph  marginTop="0px" marginBottom="0px">
                  Telegram  
                  </Paragraph>
                </Link>
                <Link
                  href="https://x.com/blockinnETH"
                  underline="none"
                  color="inherit"
                >
                  <Paragraph  marginTop="0px" marginBottom="0px">
                  X (Twitter)
                  </Paragraph>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Blackbg>
          </Container>
    </>
  );
};

export default Summary;
